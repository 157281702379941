var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" A total of 5 standard solutions with a concentration in the range of "),_c('stemble-latex',{attrs:{"content":"$5-50~\\mu\\text{M}$"}}),_vm._v(" are needed to create a Beer's Law Plot for the red dye. The stock solution of the red dye with a concentration of "),_c('stemble-latex',{attrs:{"content":"$50~\\mu\\text{M}$"}}),_vm._v(" will be provided. This solution can be used as the first standard. The other four standards are created by diluting this solution. You can only use a "),_c('stemble-latex',{attrs:{"content":"$10\\,\\text{mL}$"}}),_vm._v(" volumetric flask and "),_c('stemble-latex',{attrs:{"content":"$1\\,\\text{mL}$"}}),_vm._v(" and "),_c('stemble-latex',{attrs:{"content":"$5\\,\\text{mL}$"}}),_vm._v(" volumetric pipets to create the solutions (once created the solution can be held in a labeled beaker). ")],1),_c('p',{staticClass:"mb-2"},[_vm._v(" a) What volume of the stock solution "),_c('stemble-latex',{attrs:{"content":"$(\\text{M}_{\\text{conc}})$"}}),_vm._v(" is needed to create a "),_c('stemble-latex',{attrs:{"content":"$30\\,\\mu\\text{M (M)}_)\\text{dil})$"}}),_vm._v(" standard solution? ")],1),_c('p',{staticClass:"mb-3"},[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}}),_c('stemble-latex',{attrs:{"content":"$\\text{mL}$"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" To make this solution the "),_c('b',[_vm._v(" [B] ")]),_vm._v(" volumetric pipet(s) are used to deliver the stock solution to the "),_c('b',[_vm._v(" [C]")]),_vm._v(". ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" [B]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}}),_c('stemble-latex',{attrs:{"content":"$\\text{mL}$"}})],1),_c('p',{staticClass:"mb-3"},[_vm._v(" [C]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.items3,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}}),_c('stemble-latex',{attrs:{"content":"$\\text{mL}$"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" Deionized water is then added using a(n) "),_c('b',[_vm._v(" [D] ")]),_vm._v(" until the "),_c('b',[_vm._v(" [E] ")]),_vm._v(" of the meniscus is "),_c('b',[_vm._v(" [F]")]),_vm._v(". ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" [D]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.items4,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}}),_c('stemble-latex',{attrs:{"content":"$\\text{mL}$"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [E]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.items5,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}}),_c('stemble-latex',{attrs:{"content":"$\\text{mL}$"}})],1),_c('p',{staticClass:"mb-3"},[_vm._v(" [F]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.items6,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}}),_c('stemble-latex',{attrs:{"content":"$\\text{mL}$"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }