<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A total of 5 standard solutions with a concentration in the range of
        <stemble-latex content="$5-50~\mu\text{M}$" /> are needed to create a Beer's Law Plot for
        the red dye. The stock solution of the red dye with a concentration of
        <stemble-latex content="$50~\mu\text{M}$" /> will be provided. This solution can be used as
        the first standard. The other four standards are created by diluting this solution. You can
        only use a <stemble-latex content="$10\,\text{mL}$" /> volumetric flask and
        <stemble-latex content="$1\,\text{mL}$" /> and
        <stemble-latex content="$5\,\text{mL}$" /> volumetric pipets to create the solutions (once
        created the solution can be held in a labeled beaker).
      </p>

      <p class="mb-2">
        a) What volume of the stock solution
        <stemble-latex content="$(\text{M}_{\text{conc}})$" /> is needed to create a
        <stemble-latex content="$30\,\mu\text{M (M)}_)\text{dil})$" /> standard solution?
      </p>

      <p class="mb-3">
        <v-select
          v-model="inputs.input1"
          style="display: inline-block; max-width: 250px"
          class="ml-1"
          :items="items1"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        <stemble-latex content="$\text{mL}$" />
      </p>

      <p class="mb-2">
        To make this solution the <b>&nbsp;[B]&nbsp;</b> volumetric pipet(s) are used to deliver the
        stock solution to the <b>&nbsp;[C]</b>.
      </p>
      <p class="mb-2">
        [B]:
        <v-select
          v-model="inputs.input2"
          style="display: inline-block; max-width: 250px"
          class="ml-1"
          :items="items2"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        <stemble-latex content="$\text{mL}$" />
      </p>

      <p class="mb-3">
        [C]:
        <v-select
          v-model="inputs.input3"
          style="display: inline-block; max-width: 250px"
          class="ml-1"
          :items="items3"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        <stemble-latex content="$\text{mL}$" />
      </p>

      <p class="mb-2">
        Deionized water is then added using a(n) <b>&nbsp;[D]&nbsp;</b> until the
        <b>&nbsp;[E]&nbsp;</b> of the meniscus is <b>&nbsp;[F]</b>.
      </p>

      <p class="mb-2">
        [D]:
        <v-select
          v-model="inputs.input4"
          style="display: inline-block; max-width: 250px"
          class="ml-1"
          :items="items4"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        <stemble-latex content="$\text{mL}$" />
      </p>

      <p class="mb-2">
        [E]:
        <v-select
          v-model="inputs.input5"
          style="display: inline-block; max-width: 250px"
          class="ml-1"
          :items="items5"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        <stemble-latex content="$\text{mL}$" />
      </p>

      <p class="mb-3">
        [F]:
        <v-select
          v-model="inputs.input6"
          style="display: inline-block; max-width: 250px"
          class="ml-1"
          :items="items6"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        <stemble-latex content="$\text{mL}$" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ2S1Q7',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
      items1: [
        {text: '5', value: 'five'},
        {text: '6', value: 'six'},
        {text: '7', value: 'seven'},
        {text: '8', value: 'eight'},
      ],
      items2: [
        {text: '$1\\,\\text{mL}$', value: '1ml'},
        {text: '$5\\,\\text{mL}$', value: '5ml'},
        {text: 'both', value: 'both'},
      ],
      items3: [
        {text: 'Graduated cylinder', value: 'grad'},
        {text: '$10\\,\\text{mL}$ volumetric flask', value: 'vol'},
        {text: '$5\\,\\text{mL}$ volumetric pipet', value: 'pipet'},
        {text: 'labeled beaker', value: 'beaker'},
      ],
      items4: [
        {text: 'Erlenmeyer flask', value: 'erlenmeyer'},
        {text: 'volumetric pipet', value: 'volPipet'},
        {text: 'disposable pipet', value: 'disPipet'},
        {text: 'graduated cylinder', value: 'grad'},
      ],
      items5: [
        {text: 'Bottom', value: 'bottom'},
        {text: 'Top', value: 'top'},
        {text: 'Middle', value: 'middle'},
      ],
      items6: [
        {text: 'Above the calibration line on the volumetric flask neck', value: 'aboveFlask'},
        {text: 'Above the calibration line on the volumetric pipet neck', value: 'abovePipet'},
        {
          text: 'In line with the calibration line on the volumetric flask neck',
          value: 'inlineFlask',
        },
        {
          text: 'In line with the calibration line on the volumetric pipet neck',
          value: 'inlinePipet',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
